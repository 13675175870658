import styled, { createGlobalStyle, keyframes } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {useEffect, useRef, useState} from "react";

export const theme = {
    colors: {
        primary: '#4B0082', // Dark purple
        secondary: '#C77EB5', // Pink
        white: '#FFFFFF',
        backgroundGray: '#F8F9FA',
        lightGray: '#E9ECEF',
        mediumGray: '#DEE2E6',
        darkGray: '#343A40',
        textPrimary: '#212529',
        textSecondary: '#6C757D',
    }
};
export const GlobalStyle = createGlobalStyle`
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: ${props => props.theme.colors.backgroundGray};
    color: ${props => props.theme.colors.textPrimary};
  }
`;

export const ChatWindow = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: ${props => props.theme.colors.backgroundGray};
    scroll-behavior: smooth;
`;

export const InputArea = styled.div`
    display: flex;
    padding: 20px;
    background-color: ${props => props.theme.colors.white};
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;

export const Input = styled.input`
  flex: 1;
  padding: 12px;
  border: 1px solid ${props => props.theme.colors.lightGray};
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.lightBlue};
  }
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const ModalContent = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90vw;
    max-height: 90vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ModalImage = styled.img`
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
    width: auto;
    height: auto;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: ${props => props.theme.colors.darkGray};
`;

const ResizeHandle = styled.div`
    width: 5px;
    cursor: col-resize;
    background-color: ${props => props.theme.colors.mediumGray};
    height: 100%;
`;

export const ToggleButton = styled.button`
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    border: none;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    z-index: 10;

    &:hover {
        background-color: ${props => props.theme.colors.lightBlue};
    }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid ${props => props.theme.colors.lightGray};
  border-top: 4px solid ${props => props.theme.colors.darkBlue};
  border-radius: 50%;
  animation: ${rotate} 1s linear infinite;
  margin: 20px auto;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
`;

export const MarkdownContent = styled(ReactMarkdown)`
  font-size: 16px;
  line-height: 1.5;

  p {
    margin: 0 0 10px 0;
  }

  ul, ol {
    margin: 0 0 10px 0;
    padding-left: 20px;
  }

  pre {
    background-color: ${props => props.theme.colors.lightGray};
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
  }

  code {
    font-family: 'Courier New', Courier, monospace;
    background-color: ${props => props.theme.colors.lightGray};
    padding: 2px 4px;
    border-radius: 2px;
  }
`;

export const Header = styled.header`
  background-color: ${props => props.theme.colors.primary};
  padding: 10px 20px;
  display: flex;
  align-items: center;
`;

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

export const MainContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export const ChatContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;


export const LogoContainer = styled.div`
    padding: 20px 0 20px 20px; // Add left padding to align with other content
    margin-bottom: 20px;
`;

export const Logo = styled.img`
    width: auto; // Changed from percentage to auto
    height: 40px; // Set a fixed height, adjust as needed
    max-width: 200px;
    margin: 0; // Remove auto margins
    display: block; // Ensures the image is treated as a block element
`;

export const SidePanelContent = styled.div`
    width: ${props => props.$width - 5}px;
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.white};
    min-height: 100%; // Ensure it takes at least full height
    overflow-y: auto; // Make content scrollable if it exceeds the height
`;

export const SidePanelSection = styled.div`
    margin-bottom: 20px;
    padding: 0 20px; // Add horizontal padding to align with logo
    overflow-y: auto; // Make each section independently scrollable if needed

    h2, h3 {
        margin-top: 0;
        margin-bottom: 10px;
    }
`;

export const SidePanelContainer = styled.div`
    width: ${props => props.$isHidden ? '0' : props.$width + 'px'};
    transition: width 0.3s ease;
    background-color: ${props => props.theme.colors.secondary};
    overflow-y: auto; // Changed from hidden to auto
    overflow-x: hidden;
    box-shadow: -2px 0 5px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    height: 100vh; // Use viewport height
`;

export const SidePanel = ({ $isHidden, children }) => {
    const [width, setWidth] = useState(300);
    const [isResizing, setIsResizing] = useState(false);
    const sidePanelRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isResizing) return;
            const newWidth = window.innerWidth - e.clientX;
            setWidth(Math.max(200, Math.min(newWidth, 800)));
        };

        const handleMouseUp = () => {
            setIsResizing(false);
        };

        if (isResizing) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing]);

    return (
        <SidePanelContainer ref={sidePanelRef} $isHidden={$isHidden} $width={width}>
            <SidePanelContent $width={width}>{children}</SidePanelContent>
        </SidePanelContainer>
    );
};
export const SendButton = styled.button`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    border: none;
    padding: 12px 24px;
    margin-left: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;

    &:hover {
        background-color: ${props => props.theme.colors.primary};
    }
`;

export const Message = styled.div`
    margin-bottom: 15px;
    padding: 12px;
    border-radius: 8px;
    max-width: 70%;
  
    ${props => props.$isUser ? `
        background-color: ${props.theme.colors.secondary};
        color: ${props.theme.colors.white};
        align-self: flex-end;
        margin-left: auto;

        ${MarkdownContent} {
            color: ${props.theme.colors.white};
        }
    ` : `
        background-color: ${props.theme.colors.white};
        border: 1px solid ${props.theme.colors.lightGray};
        align-self: flex-start;
        margin-right: auto;
    `}
`;

import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import {ThemeProvider} from 'styled-components';
import remarkGfm from 'remark-gfm';
import {
    theme,
    GlobalStyle,
    AppContainer,
    ChatContainer,
    ChatWindow,
    InputArea,
    Input,
    SendButton,
    SidePanel,
    ToggleButton,
    Spinner,
    SpinnerContainer,
    MarkdownContent,
    Message,
    ModalOverlay,
    ModalContent,
    ModalImage,
    CloseButton,
    MainContainer,
    SidePanelSection,
    Logo, LogoContainer
} from './layout';

const isProd = process.env.REACT_APP_IS_PROD === 'true';  // Make sure to compare strings
const baseUrl = isProd ? "" : "http://localhost:5000";

const extractImagePaths = (text) => {
    const imageRegex = /\S*storage\S*\.png/gi;
    const imagePaths = [];
    let cleanedText = [];

// Split the text into lines and process each line
    const lines = text.split('\n');

    for (let line of lines) {
        let match;
        let lineHasMatch = false;

        while ((match = imageRegex.exec(line)) !== null) {
            let fullPath = match[0];

            const storageIndex = fullPath.indexOf('storage');
            if (storageIndex !== -1) {
                fullPath = fullPath.substring(storageIndex);
            }

            // Ensure the path starts with a slash
            if (!fullPath.startsWith('/')) {
                fullPath = '/' + fullPath;
            }

            imagePaths.push(fullPath);
            lineHasMatch = true;

            // Remove the matched part from the line
            line = line.replace(match[0], '').trim();
        }

        // Reset lastIndex of regex for next line
        imageRegex.lastIndex = 0;

        // If the line had a match and is now empty, don't add it to cleanedText
        if (!lineHasMatch || line !== '') {
            cleanedText.push(line);
        }
    }

// Join the cleaned lines back into a single string
    cleanedText = cleanedText.join('\n');

    console.log('Extracted image paths:', imagePaths); // Debugging log
    return {cleanedText, imagePaths};
};

const App = () => {
    const [input, setInput] = useState('');
    const [isSidePanelHidden, setIsSidePanelHidden] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const chatWindowRef = useRef(null);
    const inputRef = useRef(null);
    const [imagePaths, setImagePaths] = useState([]);
    const [modalImage, setModalImage] = useState(null);
    const [modalImageDimensions, setModalImageDimensions] = useState({width: 0, height: 0});
    const welcomeMessage = { text: "Welcome to Extenda Support! Please keep the conversations about a single topic.\n\r If you want to change the topic type /reset.\n\rHow can I help you today?", isUser: false };
    const [messages, setMessages] = useState([welcomeMessage]);


    const handleModalImageLoad = (event) => {
        const img = event.target;
        const aspectRatio = img.naturalWidth / img.naturalHeight;

        let newWidth = img.naturalWidth;
        let newHeight = img.naturalHeight;

        // If the image is smaller than 300px in both dimensions, enlarge it
        if (newWidth < 300 && newHeight < 300) {
            if (aspectRatio > 1) {
                newWidth = 300;
                newHeight = newWidth / aspectRatio;
            } else {
                newHeight = 300;
                newWidth = newHeight * aspectRatio;
            }
        }

        // Ensure the image doesn't exceed 80% of viewport dimensions
        const maxWidth = window.innerWidth * 0.8;
        const maxHeight = window.innerHeight * 0.8;

        if (newWidth > maxWidth) {
            newWidth = maxWidth;
            newHeight = newWidth / aspectRatio;
        }

        if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = newHeight * aspectRatio;
        }

        setModalImageDimensions({width: newWidth, height: newHeight});
    };


    const handleImageClick = (imagePath) => {
        setModalImage(imagePath);
        setModalImageDimensions({width: 0, height: 0});
    };

    const closeModal = () => {
        setModalImage(null);
    };

    useEffect(() => {
        const storedSessionId = localStorage.getItem('chatSessionId');
        if (storedSessionId) {
            setSessionId(storedSessionId);
            fetchChatHistory(storedSessionId);
        }
    }, []);

    const fetchChatHistory = async (sessionId) => {
        try {
            const response = await axios.get(`${baseUrl}/api/chat-history?sessionId=${sessionId}`);
            const processedMessages = response.data.map(message => {
                if (!message.isUser) {
                    const {cleanedText, imagePaths: newPaths} = extractImagePaths(message.text);
                    setImagePaths(prevPaths => [...new Set([...prevPaths, ...newPaths])]);
                    return {...message, text: cleanedText};
                }
                return message;
            });
            if (processedMessages.length === 0) {
                const welcomeMessage = "Welcome to Extenda Support how can I help you today?";
                processedMessages.push({text: welcomeMessage, isUser: false});
            }
            setMessages(processedMessages);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    const scrollToBottom = () => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        if (!isLoading && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isLoading, messages]);

    const handleSend = async () => {
        if (input.trim()) {
            if (input.trim().toLowerCase() === '/reset') {
                await handleReset();
            } else {
                const newMessage = {text: input, isUser: true};
                setMessages(prevMessages => [...prevMessages, newMessage]);
                setInput('');
                setIsLoading(true);

                try {
                    const response = await axios.post(`${baseUrl}/api/chat`, {
                        message: input,
                        sessionId: sessionId
                    });

                    const {cleanedText, imagePaths: newPaths} = extractImagePaths(response.data.response);
                    setMessages(prevMessages => [...prevMessages, {text: cleanedText, isUser: false}]);
                    setImagePaths(newPaths); // Set only the new images

                    if (!sessionId) {
                        setSessionId(response.data.sessionId);
                        localStorage.setItem('chatSessionId', response.data.sessionId);
                    }
                } catch (error) {
                    console.error('Error sending message:', error);
                    setMessages(prevMessages => [...prevMessages, {
                        text: "Sorry, I'm having trouble responding right now.",
                        isUser: false
                    }]);
                } finally {
                    setIsLoading(false);
                }
            }
        }
    };

    const handleReset = async () => {
        setIsLoading(true);
        try {
            await axios.post(`${baseUrl}/api/reset`, {sessionId});
            setMessages([]);
            setImagePaths([]);
            setInput('');
            setMessages([{text: "Chat history has been reset.", isUser: false}]);
        } catch (error) {
            console.error('Error resetting chat:', error);
            setMessages([{text: "Sorry, I couldn't reset the chat history. Please try again.", isUser: false}]);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle/>
            <AppContainer>
                <MainContainer>
                    <LogoContainer>
                        <Logo src="/extendago_color.png" alt="Extenda Retail"/>
                    </LogoContainer>
                    <ChatContainer>
                        <ChatWindow ref={chatWindowRef}>
                            {messages.map((message, index) => (
                                <Message key={index} $isUser={message.isUser} theme={theme}>
                                    <MarkdownContent remarkPlugins={[remarkGfm]}>
                                        {message.text}
                                    </MarkdownContent>
                                </Message>
                            ))}
                            {isLoading && (
                                <SpinnerContainer>
                                    <Spinner/>
                                </SpinnerContainer>
                            )}
                        </ChatWindow>
                        <InputArea>
                            <Input
                                ref={inputRef}
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Type your message here..."
                                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                                disabled={isLoading}
                            />
                            <SendButton onClick={handleSend} disabled={isLoading}>
                                Send
                            </SendButton>
                        </InputArea>
                    </ChatContainer>
                    <SidePanel $isHidden={isSidePanelHidden}>
                        <SidePanelSection>
                            <h3>Images ({imagePaths.length})</h3>
                            {imagePaths.map((path, index) => (
                                <div key={index}>
                                    <img
                                        src={`${baseUrl}/api/image${path}`}
                                        onClick={() => handleImageClick(path)}
                                        alt={`Image ${index + 1}`}
                                        style={{maxWidth: '100%', marginBottom: '10px', cursor: 'pointer'}}
                                        onError={(e) => {
                                            console.error(`Error loading image: ${path}`);
                                            e.target.onerror = null;
                                            e.target.src = 'https://via.placeholder.com/150?text=Image+Load+Error';
                                        }}
                                    />
                                </div>
                            ))}
                        </SidePanelSection>
                        <SidePanelSection>
                            <h2>Chat Session</h2>
                            <p>Session ID: {sessionId || 'Not established yet'}</p>
                        </SidePanelSection>
                    </SidePanel>
                </MainContainer>
                <ToggleButton
                    $isHidden={isSidePanelHidden}
                    onClick={() => setIsSidePanelHidden(!isSidePanelHidden)}
                >
                    {isSidePanelHidden ? '>' : '<'}
                </ToggleButton>
                {modalImage && (
                    <ModalOverlay onClick={closeModal}>
                        <ModalContent onClick={(e) => e.stopPropagation()}>
                            <CloseButton onClick={closeModal}>&times;</CloseButton>
                            <ModalImage
                                src={`${baseUrl}/api/image${modalImage}`}
                                onLoad={handleModalImageLoad}
                                alt="Enlarged view"
                                onError={(e) => {
                                    console.error(`Error loading modal image: ${modalImage}`);
                                    e.target.onerror = null;
                                    e.target.src = 'https://via.placeholder.com/300?text=Modal+Image+Load+Error';
                                }}
                            />
                        </ModalContent>
                    </ModalOverlay>
                )}
            </AppContainer>
        </ThemeProvider>
    );
};

export default App;